import { Flex, useMantineTheme } from '@mantine/core';
import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { Text } from '~/domains/design-system/Text';
import { IconChevronRight } from '~/domains/design-system/icons/IconChevronRight';
import { Routes } from '~/domains/common/constants/routes';
import dt from '~/testing';

function ActionLink({ href, children }: { href: string; children: React.ReactNode }) {
  const theme = useMantineTheme();

  return (
    <Text
      data-test-id={dt.common.components.manageInvites}
      href={href}
      component={Link}
      variant="subhead-small"
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors.blue[8],
        '&:hover': {
          textDecoration: 'underline',
        },
        svg: {
          pointerEvents: 'none',
        },
      }}
    >
      {children}
      <IconChevronRight color={theme.colors.blue[8]} />
    </Text>
  );
}

export function ContestCardActions({
  contestId,
  isInvitesManagerEnabled,
  isMassCommunicationEnabled,
}: {
  contestId: string;
  isInvitesManagerEnabled: boolean;
  isMassCommunicationEnabled: boolean;
}) {
  const { t } = useTranslation('contest');
  const theme = useMantineTheme();

  return (
    <Flex
      justify="space-between"
      mt="12px"
      mx="-md"
      mb="-md"
      px="16px"
      py="6px"
      sx={{
        borderTop: `1px solid ${theme.colors.gray2[0]}`,
      }}
    >
      {isInvitesManagerEnabled && (
        <ActionLink href={Routes.invitesManager(contestId)}>
          {t('common.cardActions.inviteManager')}
        </ActionLink>
      )}
      {isMassCommunicationEnabled && (
        <ActionLink href="">{t('common.cardActions.massCommunication')}</ActionLink>
      )}
    </Flex>
  );
}
