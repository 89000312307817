export default {
  common: {
    viewAll: 'View all',
  },
  featured: {
    title: 'Featured Contests',
  },
  myEntriesNew: {
    title__hasPlural: {
      one: 'Missing picks for 1 contest',
      other: 'Missing picks for {{count}} contests',
    },
  },
  myEntries: {
    title: 'My Entries',
    entryFee: 'Entry',
    progress: '{{totalSpots}} of {{maxEntries}}',
    prizes: 'Prizes',
    threedot: {
      withdraw: 'Withdraw entry',
    },
    actions: {
      edit: 'Edit',
      view: 'View',
    },
  },
  myEntriesLinks: {
    title: 'My Contests',
    entriesCount__hasPlural: {
      one: '{{count}} Entry',
      other: '{{count}} Entries',
    },
    live: 'Live',
    finalized: 'Settled',
    upcoming: 'Upcoming',
  },
};
