// Swagger: https://api-dev.splashsports.com/commissioner-service/api/docs
import queryString from 'query-string';

import type {
  Channel,
  ChannelOnboardingPayload,
  CommissionerAssetType,
  GetChannelResponse,
} from '~/domains/commissioner/interfaces/channel.interface';
import type { GetCommissionerFollowersResponse } from '~/domains/commissioner/interfaces/follower.interface';
import type {
  GetCommissionerPostsResponse,
  Post,
} from '~/domains/commissioner/interfaces/post.interface';
import type { GetPostCommentResponse } from '~/domains/commissioner/interfaces/post-comment.interface';
import { convertPaginationToSwagFormat } from '~/domains/common/utils/convertPaginationToSwagFormat';
import { createQueryString } from '~/domains/common/utils/queryString';
import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';
import type {
  GetCommissionerRewardsRequirementResponse,
  GetCommissionerRewardsRulesResponse,
  GetRecentRewardsResponse,
} from '~/domains/commissioner/interfaces/rewards.interface';
import type {
  GetContestChatResponse,
  GetLiveLikeProfileResponse,
} from '~/domains/commissioner/interfaces/chat.interface';
import type { OverviewTimeframe } from '~/domains/commissioner/console/enum';
import type { Group, Member } from '~/domains/commissioner/interfaces/groups.interface';

import {
  commissionerImageUploadService,
  commissionerService,
} from './commissioner-service.service.instance';

export const postCommissionerChannelData = async (payload: ChannelOnboardingPayload) => {
  const response = await commissionerService.post<GetChannelResponse>('/channels', payload);
  return response.data;
};

export const patchCommissionerChannelData = async (payload, id) => {
  const response = await commissionerService.patch<GetChannelResponse>(`/channels/${id}`, payload);
  return response.data;
};

/** @deprecated use getChannelsByQuery instead */
export const getCommissionersChannelsByQuery = async ({
  query,
  limit,
  offset = 0,
}: {
  query: string;
  limit?: number;
  offset?: number;
}) => {
  const { pageSize, page } = convertPaginationToSwagFormat({ limit, offset });
  const response = await commissionerService.get<PaginatedData<Channel>>(
    `/channels${createQueryString({ q: query, pageSize, page })}`
  );
  return response.data;
};

type InfiniteData<T> = {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  id: string;
  data: T[];
};

export const getChannelsByQuery = async (payload: {
  q: string;
  page?: number;
  pageSize?: number;
}) => {
  const query = queryString.stringify(payload);
  const response = await commissionerService.get<InfiniteData<Channel>>(`/channels?${query}`);

  return response.data;
};

export const getCommissionerChannelDataBySlug = async (slug: string) => {
  const response = await commissionerService.get<GetChannelResponse>(`/channels/slug/${slug}`);
  return response.data;
};

export const getCommissionerChannelDataByUserId = async (userId: string) => {
  const response = await commissionerService.get<GetChannelResponse>(`/channels/user/${userId}`);
  return response.data;
};

export const getCommissionerChannelDataByHandle = async (handle: string) => {
  const response = await commissionerService.get<GetChannelResponse>(`/channels/handle/${handle}`);
  return response.data;
};

export const getCommissionerChannelHandleAvailability = async (handle: string) => {
  const response = await commissionerService.get<{
    data: {
      isAvailable: boolean;
    };
  }>(`/channels/handles/${handle}/available`);
  return response.data;
};

export const getCommissionerChannelDataByUser = async () => {
  const response = await commissionerService.get<GetChannelResponse>('/channels/user');
  return response.data;
};

export const uploadCommissionerAsset = async (payload, path: CommissionerAssetType) => {
  const response = await commissionerImageUploadService.post(`/${path}`, payload);
  return response.data;
};

/** @deprecated */
export const followCommissioner = async (channelId, username) => {
  const response = await commissionerService.post(`/channels/${channelId}/followers`, { username });
  return response.data;
};

export const followChannel = async (channelId: string) => {
  const response = await commissionerService.post(`/channels/${channelId}/followers`);
  return response.data;
};

export const unfollowChannel = async (channelId: string) => {
  const response = await commissionerService.delete(`/channels/${channelId}/followers`);
  return response.data;
};

type InfiniteDataPayload = {
  page?: number;
  pageSize?: number;
  contestId?: string;
  contestInviteStatuses?: string;
  q?: string;
};

export const getChannelFollowers = async (channelId: string, payload: InfiniteDataPayload) => {
  const query = queryString.stringify(payload);
  const response = await commissionerService.get<GetCommissionerFollowersResponse>(
    `/channels/${channelId}/followers?${query}`
  );
  return response.data;
};

export const getChannelFollowees = async (channelId: string, payload: InfiniteDataPayload) => {
  const query = queryString.stringify(payload);
  const response = await commissionerService.get<GetCommissionerFollowersResponse>(
    `/channels/${channelId}/followees?${query}`
  );
  return response.data;
};

export const getCommissionerPosts = async (channelId, payload) => {
  const query = queryString.stringify(payload);
  const response = await commissionerService.get<GetCommissionerPostsResponse>(
    `/channels/${channelId}/posts?${query}`
  );
  return response.data;
};

export const createNewPost = async (channelId, post: Post) => {
  const response = await commissionerService.post(`/channels/${channelId}/posts`, post);
  return response.data;
};

export const likePost = async (postId: string) => {
  const response = await commissionerService.post(`/posts/${postId}/likes`);
  return response.data;
};

export const unlikePost = async (postId: string) => {
  const response = await commissionerService.delete(`/posts/${postId}/likes`);
  return response.data;
};

export const getComments = async (postId: string) => {
  const response = await commissionerService.get<GetPostCommentResponse>(
    `/posts/${postId}/comments`
  );
  return response.data;
};

export const addComment = async (postId: string, text: string) => {
  const response = await commissionerService.post(`/posts/${postId}/comments`, {
    text,
  });
  return response.data;
};

export const getCommissionerTotalHandle = async (channelId: string) => {
  const response = await commissionerService.get(`/channels/${channelId}/handles/total`);
  return response.data;
};

export const getCommissionerTotalRewards = async (channelId: string) => {
  const response = await commissionerService.get(`/channels/${channelId}/rewards/total`);
  return response.data;
};

export const getCommissionerRecentRewards = async (channelId: string) => {
  const response = await commissionerService.get<GetRecentRewardsResponse>(
    `/channels/${channelId}/rewards`
  );
  return response.data;
};

export const getCommissionerRewardsRules = async () => {
  const response =
    await commissionerService.get<GetCommissionerRewardsRulesResponse>('/rewards/rules');
  return response.data?.data;
};

export const getCommissionerRewardsRequirements = async () => {
  const response =
    await commissionerService.get<GetCommissionerRewardsRequirementResponse>(
      '/rewards/requirements'
    );
  return response.data?.data;
};

export const getLiveLikeProfile = async () => {
  const response = await commissionerService.get<GetLiveLikeProfileResponse>('/livelike/profiles');

  return response.data?.data;
};

export const getContestChat = async (contestId: string) => {
  const response = await commissionerService.get<GetContestChatResponse>(
    `/livelike/chats/contests/${contestId}`
  );

  return response.data?.data;
};

export const getCommissionerConsoleOverview = async (timeframe: OverviewTimeframe) => {
  const response = await commissionerService.get(`/channels/user/overview?timeframe=${timeframe}`);
  return response.data;
};

export const createGroup = async (name: string) => {
  const response = await commissionerService.post<{ id: string; data: Group }>(`/groups`, {
    name,
  });
  return response.data?.data;
};

export const getGroups = async ({
  limit = 20,
  offset = 0,
}: {
  limit?: number;
  offset?: number;
}) => {
  const { pageSize, page } = convertPaginationToSwagFormat({ limit, offset });

  const response = await commissionerService.get<PaginatedData<Group>>(
    `/groups${createQueryString({ pageSize, page })}`
  );
  return response.data;
};

export const updateGroup = async (id: string, name: string) => {
  const response = await commissionerService.post<{ id: string; data: Group }>(`/groups/${id}`, {
    name,
  });
  return response.data?.data;
};

export const deleteGroup = async (id: string) => {
  await commissionerService.delete(`/groups/${id}`);
};

export const addMembers = async (id: string, userIds: string[]) => {
  await commissionerService.post(`/groups/${id}/members`, {
    userIds,
  });
};

export const getMembers = async (
  id: string,
  {
    limit = 20,
    offset = 0,
  }: {
    limit?: number;
    offset?: number;
  }
) => {
  const { pageSize, page } = convertPaginationToSwagFormat({ limit, offset });

  const response = await commissionerService.get<PaginatedData<Member>>(
    `/groups/${id}/members${createQueryString({ pageSize, page })}`
  );
  return response.data;
};

export const removeMembers = async (id: string, userIds: string[]) => {
  await commissionerService.delete(`/groups/${id}/members`, { data: { userIds } });
};

export const inviteChannelToContests = async (channelId: string, contestIds: string[]) => {
  const response = await commissionerService.post(`/channels/${channelId}/invites`, {
    contestIds,
  });
  return response.data;
};

export type ContestInvitePayload = {
  allContests?: boolean;
  allUsers?: boolean;
  contestIds?: string[];
  emails?: string[];
  error?: string;
  excludedContestIds?: string[];
  excludedGroupIds?: string[];
  excludedUserIds?: string[];
  groupIds?: string[];
  userIds?: string[];
};

type CreateContestResponse = {
  id: string;
  data: {
    count: number;
  };
};

export const createContestInvite = async (contestId: string, payload: ContestInvitePayload) => {
  const response = await commissionerService.post<CreateContestResponse>(
    `/contests/${contestId}/invites`,
    payload,
    {
      headers: {
        'splash-accept-version': '2',
      },
    }
  );
  return response.data;
};

type ChannelInvitesOverviewResponse = {
  total: number;
  contests: {
    id: string;
    name: string;
    total: number;
  }[];
};

export const getChannelInvitesOverview = async (channelId: string) => {
  const response = await commissionerService.get<ChannelInvitesOverviewResponse>(
    `/channels/${channelId}/invites/overview`
  );
  return response.data;
};

type ContestInvitesStatsResponse = {
  id: string;
  data: {
    description: string;
    inAppInvitesStats: {
      /** Sum of users invited to the contest' */
      invited: number;
      /** Count of users who have not accepted the invitation and entered the contest yet */
      pending: number;
      /** Count of users who have accepted the invitation and entered the contest */
      accepted: number;
    };
    emailInvites: number;
    invitesProcessing: boolean;
  };
};

export const getContestInvitesStats = async (contestId: string) => {
  const response = await commissionerService.get<ContestInvitesStatsResponse>(
    `/contests/${contestId}/invites/stats`
  );
  return response.data.data;
};

type ContestInviteDto = {
  id: string;
  userId: string;
  channelId: string;
  channelName: string;
  handle: string;
  status: 'ACCEPTED' | 'PENDING';
  createdAt: Date;
};

export const getContestInvites = async (
  channelId: string,
  contestId: string,
  payload: InfiniteDataPayload
) => {
  const query = queryString.stringify(payload);

  const response = await commissionerService.get<InfiniteData<ContestInviteDto>>(
    `/channels/${channelId}/contests/${contestId}/invites?${query}`
  );
  return response.data;
};
